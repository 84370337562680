<template>
  <div class="inner-div">
    <div class="flex-row space-between">
      <div id="section-headline">
        {{ linkedSearchTitle }}
      </div>
      <div>
        <VasionButton
          id="save-button"
          class="add-cancel"
          name="save-button"
          classProp="primary"
          :isDisabled="!isDirty"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
        <VasionButton
          id="cancel-button"
          class="add-cancel"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="flex-row topBottomMargin">
      <VasionInput
        id="linked-search-name"
        v-model="linkedSearchName"
        title="Linked Search Name"
        inputType="top-white"
        name="linked-search-name"
        placeholder="Enter Name..."
        :width="'392'"
        @input="isDirty = true"
      />
    </div>
    <div class="flex-row topBottomMargin">
      <VasionDropList
        id="primary-attribute-form"
        v-slot="slotItem"
        v-model="selectedObject"
        title="PRIMARY OBJECT"
        name="primary-attribute-form"
        type="plain-list"
        :dataArray="objectList"
        width="392"
        valueName="value"
        :isDisabled="linkedSearchId > 0"
        displayName="name"
        @input="isDirty = true"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
      <div class="middle-space" />
      <VasionDropList
        v-slot="slotItem"
        v-model="resultObject"
        :dataArray="objectList"
        width="392"
        title="RESULT OBJECT"
        type="plain-list"
        displayName="name"
        :isDisabled="linkedSearchId > 0"
        valueName="value"
        @input="isDirty = true"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <div class="fixed-height">
      <table>
        <tr class="headerRow">
          <th v-for="(headerColumn, column) in dataTable.Columns.Values" :key="column" class="table-header">
            {{ headerColumn }}
          </th>
        </tr>
        <tr v-for="(rowValues, row) in dataTable.Rows.Values" :key="row">
          <td class="table-data" :class="{'grey-background': row % 2 === 0}">
            {{ rowValues.name }}
          </td>
          <td class="table-data" :class="{'grey-background': row % 2 === 0}">
            <VasionDropList
              v-slot="slotItem"
              v-model="rowValues.value"
              :dataArray="searchableAttributeFields"
              width="352"
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </td>
        </tr>
      </table>
    </div>
    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: 'NewEditLinkedSearch',
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      dataTable: {
        Columns: { Values: ['Document Field', 'Searchable Field'] },
        Rows: {
          Values: [
            { name: '', value: {} },
          ],
        },
      },
      isDirty: false,
      linkedSearchId: 0,
      linkedSearchName: '',
      resultObject: '',
      savedLinkedSearchValues: [],
      searchableAttributeFields: [{
        name: '',
        value: 0,
      }],
      selectedObject: '',
      showLeaveDialog: false,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    activeForm() { return this.$store.state.attributeForm.activeIndexForm },
    fields() { return this.$store.state.common.indexFields },
    indexFieldList() {
      const fieldsList = this.fields
      fieldsList.unshift({
        name: '',
        value: 0,
      })
      return fieldsList
     },
    linkedSearchTitle() { return this.linkedSearchName ? this.linkedSearchName : 'Untitled Link Search' },
    objectList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
  },
  watch: {
    async resultObject() {
      await this.$store.dispatch('common/getIndexFieldsForForm', this.resultObject.value)
      this.searchableAttributeFields = this.indexFieldList
      this.savedLinkedSearchValues = this.savedLinkedSearchValues.map(value => {
        return {
          name: value.docFieldName,
          value: this.searchableAttributeFields.find(field => { return value.searchFieldName === field.value }),
        }
      })
      this.updateSearchableFields()
    },
    async selectedObject() {
      await this.$store.dispatch('common/getIndexFieldsForForm', this.selectedObject.value)
        this.dataTable.Rows.Values = this.fields.filter(field => {
          if (field.name !== '') {
            return true
          } else {
            return false
          }
        }).map((f) => {
          return { name: f.name, value: {}, fieldNameValue: f.value }
        })
      this.updateSearchableFields()
    },
  },
  async created() {
    await this.$store.dispatch('attributeForm/getForms')
    if (this.$route.params.linkedSearchId !== 0) {
      const linkedSearch = await this.$store.dispatch('search/loadLinkedSearch', this.$route.params.linkedSearchId)
      this.linkedSearchId = this.$route.params.linkedSearchId
      this.linkedSearchName = linkedSearch.linkSearchName
      this.selectedObject = this.objectList.find(object => { return object.value === linkedSearch.documentObjectID })
      this.resultObject = this.objectList.find(object => { return object.value === linkedSearch.searchInObjectID })
      this.savedLinkedSearchValues = linkedSearch.searchFieldMapList
    }
  },
  methods: {
    cancel() { this.$router.push({ name: 'AdminLinkedSearchList' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    async save() {
      if (!this.validate()) {
        this.snackbarTitle = 'Error'
        this.snackbarImage = false
        this.showSnackbarBool = true
        return
      }
      const fieldList = this.dataTable.Rows.Values.filter(field => {
        if (field.value.value) {
          return true
        } else {
          return false
        }
      }).map((f) => {
        return {
          docFieldName: f.fieldNameValue,
          searchFieldName: f.value.value,
        }
      })
      const payload = {
        linkSearchID: this.linkedSearchId,
        linkSearchName: this.linkedSearchName,
        documentObjectID: this.selectedObject.value,
        searchInObjectID: this.resultObject.value,
        searchFieldMapList: fieldList,
      }
      const response = await this.$store.dispatch('search/saveLinkedSearch', payload)
      if (response?.Value === 'True') {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Linked Search Saved'
        this.snackbarImage = true
        this.showSnackbarBool = true
        this.isDirty = false
        setTimeout(() => {
          this.$router.push({ name: 'AdminLinkedSearchList' })
        }, 1500)
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Linked Search Not Saved'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    updateSearchableFields() {
      this.savedLinkedSearchValues.forEach(savedLinkedSearchValue => {
         this.dataTable.Rows.Values.forEach(tableValue => {
           if (savedLinkedSearchValue.name === tableValue.fieldNameValue) {
             tableValue.value = savedLinkedSearchValue.value
           }
         })
      })
    },
    validate() {
      let canSave = true
      this.snackbarSubTitle = ''
      if (this.linkedSearchName === '') {
        this.snackbarSubTitle += 'Missing Linked Search Name. \n'
        canSave = false
      }
      if (this.linkedSearchName.trim() === '') {
        this.snackbarSubTitle += 'Linked Search Name cannot only include spaces. \n'
        canSave = false
      }
      if (this.selectedObject === '') {
        this.snackbarSubTitle += 'Please select a Primary Object. \n'
        canSave = false
      }
      if (this.resultObject === '') {
        this.snackbarSubTitle += 'Please select a Result Object. \n'
        canSave = false
      }
      let updatedOneRow = false

      this.dataTable.Rows.Values.forEach(row => {
        if (row.value.value && row.value.value !== '' && row.value.value !== 0 && row.value !== {} && Object.keys(row.value).length !== 0) {
          updatedOneRow = true
        }
      })
      if (!updatedOneRow) {
        this.snackbarSubTitle += 'Please fill out at least one mapping field \n'
        canSave = false
      }
      return canSave
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

  #section-headline {
    @include Headline;
  }
  .inner-div {
    margin: 20px 16px 0 16px;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .topBottomMargin {
    margin: 10px 0px;
  }
  .space-between {
    justify-content: space-between;
  }
  .middle-space {
    margin: 0px 15px;
  }
  .headerRow {
    background-color: $table-header-bg;
    color: $table-header-color;
  }
  table {
    width: 100%;
  }
  .fixed-height {
    overflow: auto;
    margin-top: 30px;
    height: calc(100vh - 360px);
  }
  .table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $table-header-bg;
    color: $table-header-color;
    padding: 15px 0px 15px 15px;
    white-space: nowrap;
    width: 100%;
  }
  .table-header label {
    font-weight: normal !important;
  }
  .table-data {
    padding: 10px 0 10px 15px;
  }
  .number-input {
    padding-right: 10px;
  }

  .grey-background {
    background-color: $grey-50;
  }
</style>
